import API from '@/libs/axios'
import { perPageOptions } from '../../constants'

export default {
  namespaced: true,
  state: {
    detail: null,
    loading: false,
    recharges: {
      items: [],
      perPage: 15,
      currentPage: 1,
      totalRows: 3,
      pageOptions: perPageOptions,
      searchTerm: '',
      loading: false,
    },
  },
  getters: {},
  mutations: {
    SET_DETAIL(state, payload) {
      state.detail = payload
    },
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_PER_PAGE(state, perPage) {
      state.perPage = perPage
    },
    SET_CURRENT_PAGE(state, currentPage) {
      state.currentPage = currentPage
    },
    SET_TOTAL_ROWS(state, totalRows) {
      state.totalRows = totalRows
    },
    SET_SEARCH_TERM(state, searchTerm) {
      state.searchTerm = searchTerm
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    async get({ commit, state }, { currentPage }) {
      try {
        commit('SET_LOADING', true)
        const { data: response } = await API.get('recharge-methods', {
          params: {
            page: currentPage,
            per_page: state.perPage,
            searchTerm: state.searchTerm,
          },
        })

        commit('SET_PER_PAGE', response?.data?.per_page)
        commit('SET_CURRENT_PAGE', response?.data?.current_page)
        commit('SET_TOTAL_ROWS', response?.data?.total)
        commit('SET_ITEMS', response?.data?.data)
        commit('SET_LOADING', false)
        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
    async getDetail({ commit, state }, { id }) {
      try {
        commit('SET_LOADING', true)
        commit('SET_DETAIL', null)
        const { data: response } = await API.get(`recharge-methods/${id}`)

        commit('SET_DETAIL', response?.data)
        commit('SET_LOADING', false)
        return response?.data
      } catch (error) {
        console.log(error)
      }
    },

    async save({ commit, state }, payload) {
      try {
        commit('SET_LOADING', true)
        const { data: response } = await API.post('recharge-methods', payload)

        if (!payload?.id) {
          commit('SET_ITEMS', [...state.items, response?.data])
          commit('SET_LOADING', false)
        } else {
          commit(
            'SET_ITEMS',
            state.items.map((item) =>
              item.id === payload.id ? response?.data : item
            )
          )
          commit('SET_LOADING', false)
        }
        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
    async delete({ commit, state }, payload) {
      try {
        commit('SET_LOADING', true)
        const { data: response } = await API.delete('recharge-methods', {
          params: payload,
        })
        if (response?.success) {
          const items = state.items.filter((item) => item.id !== payload.id)
          commit('SET_ITEMS', items)
        }
        commit('SET_LOADING', false)
      } catch (error) {
        console.log(error)
      }
    },
    perPageChange({ commit }, perPage) {
      commit('SET_PER_PAGE', perPage)
    },
    searchTermChange({ commit }, searchTerm) {
      commit('SET_SEARCH_TERM', searchTerm)
    },

    async documentVerify({ commit, state }, { type, id, status }) {
      try {
        const { data: response } = await API.post(`document-verify/${type}`, {
          id,
          status: status,
        })

        const dvs = state.detail.document_verifications

        const dv = dvs.findIndex((dv) => dv.id === response.data.id)

        dvs[dv] = {
          ...dvs[dv],
          status: response.data.status,
          status_text: response.data.status_text,
        }

        // update document verification status
        commit('SET_DETAIL', {
          ...state.detail,
          document_verifications: dvs,
        })

        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
  },
}
