import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import auth from './auth';
import clients from './clients';
import companies from './companies';
import products from './products';
import invoicing from './invoicing';
import recharges from './recharges';
import withdrawals from './withdrawals';
import admins from './admins';
import documentDetail from './document-detail';
import sendMoney from './send-money';
import clientDetail from './client-detail';
import roles from './roles';
import dashboard from './dashboard';
import help from './help';
import rechargeMethods from './recharge-methods';
import rechargeMethodDetail from './recharge-method-detail';
import paymentOptions from './payment-options';
import paymentOptionAccounts from './payment-option-accounts';
import paymentOptionDetail from './payment-option-detail';
import transactions from './transactions';
import activityLog from './activity-log';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		app,
		appConfig,
		verticalMenu,
		auth,
		clients,
		companies,
		products,
		invoicing,
		recharges,
		withdrawals,
		admins,
		documentDetail,
		sendMoney,
		clientDetail,
		roles,
		dashboard,
		help,
		rechargeMethods,
		rechargeMethodDetail,
		paymentOptions,
		paymentOptionDetail,
		paymentOptionAccounts,
		transactions,
		activityLog,
	},
	strict: process.env.DEV,
});
