import API from '@/libs/axios';
import {perPageOptions} from '../../constants';
import moment from 'moment'

export default {
	namespaced: true,
	state: {
		items: [],
		perPage: 15,
		currentPage: 1,
		totalRows: 3,
		pageOptions: perPageOptions,
		searchTerm: '',
		loading: false,
		detail: null,
		filters: {
			dates: [
				// start of day
				moment().startOf('day').toDate(),
				// end of day
				moment().endOf('day').toDate(),
			],
			paymentMethod: null,
			gestor: null,
			bank: null,
		}
	},
	getters: {},
	mutations: {
		SET_ITEMS(state, items) {
			state.items = items;
		},
		SET_PER_PAGE(state, perPage) {
			state.perPage = perPage;
		},
		SET_CURRENT_PAGE(state, currentPage) {
			state.currentPage = currentPage;
		},
		SET_TOTAL_ROWS(state, totalRows) {
			state.totalRows = totalRows;
		},
		SET_SEARCH_TERM(state, searchTerm) {
			state.searchTerm = searchTerm;
		},
		SET_LOADING(state, loading) {
			state.loading = loading;
		},
		SET_LOADING_DETAIL(state, loading) {
			state.loading = loading;
		},
		SET_DETAIL(state, detail) {
			state.detail = detail;
		},
		SET_FILTERS(state, filters) {
			state.filters = filters
		},
	},
	actions: {
		updateItems({ commit, state }, payload) {
			// array of items to update
			const items = JSON.parse(JSON.stringify(state.items))
			// verify if item exists
			const index = items.findIndex((item) => item.id === payload.id)
			// if no exists, add to array
			if (index === -1) {
			  items.unshift(payload)
			}
			commit('SET_ITEMS', items)
		},
		async get({commit, state}, {currentPage, id, status, userId}) {
			try {
				commit('SET_LOADING', true);
				commit('SET_ITEMS', [])

				const {data: response} = await API.get('withdrawals', {
					params: {
						page: currentPage,
						per_page: state.perPage,
						searchTerm: state.searchTerm,
						id: id,
						status: status,
            			dates: state.filters.dates,
						user_id: userId,
						filters: state.filters
					},
				});

				commit('SET_PER_PAGE', response?.data?.per_page);
				commit('SET_CURRENT_PAGE', response?.data?.current_page);
				commit('SET_TOTAL_ROWS', response?.data?.total);
				commit('SET_ITEMS', response?.data?.data);
				commit('SET_LOADING', false);
				return response?.data;
			} catch (error) {
				console.log(error);
			}
		},
		async getDetail({commit, state}, payload) {
			try {
				commit('SET_LOADING_DETAIL', true);
				const {data: response} = await API.get(`withdrawals/${payload.id}`);
				commit('SET_LOADING_DETAIL', false);

				if (!payload?.no_set_info) commit('SET_DETAIL', response?.data);

				return response?.data;
			} catch (error) {
				console.log(error);
			}
		},
		//approve or reject recharge
		async approve({commit, state}, payload) {
			try {
				commit('SET_LOADING_DETAIL', true);
				const {data: response} = await API.put(`withdrawals/${payload.id}`, {
					id: payload.id,
					status: payload.status,
					motive_rejected: payload.motive_rejected,
					reference_transfer_money: payload.reference_transfer_money,
				});
				commit('SET_LOADING_DETAIL', false);

				commit('SET_DETAIL', {...state.detail, ...response?.data});

				// update items

				if (response.success) {
					const items = state.items.filter((item) => item.id !== payload.id)
					commit('SET_ITEMS', items)
				}
				// commit(
				// 	'SET_ITEMS',
				// 	state.items.map(item =>
				// 		item.id === payload.id ? {...item, ...response?.data} : item
				// 	)
				// );

				return response;
			} catch (error) {
				console.log(error);
			}
		},
		async save({commit, state}, payload) {
			try {
				commit('SET_LOADING', true);
				const {data: response} = await API.post('withdrawals', payload);

				if (!payload?.id) {
					commit('SET_ITEMS', [...state.items, response?.data]);
					commit('SET_LOADING', false);
				} else {
					commit(
						'SET_ITEMS',
						state.items.map(item =>
							item.id === payload.id ? response?.data : item
						)
					);
					commit('SET_LOADING', false);
				}
				return response?.data;
			} catch (error) {
				console.log(error);
			}
		},
		async delete({commit, state}, payload) {
			try {
				commit('SET_LOADING', true);
				const {data: response} = await API.delete('withdrawals', {
					params: payload,
				});
				if (response?.success) {
					const items = state.items.filter(item => item.id !== payload.id);
					commit('SET_ITEMS', items);
				}
				commit('SET_LOADING', false);
			} catch (error) {
				console.log(error);
			}
		},

		async deleteImage({commit, state}, payload) {
			try {
				const {data: response} = await API.delete('withdrawals/image', {
					params: payload,
				});

				return response;
			} catch (error) {
				console.log(error);
			}
		},

		perPageChange({commit}, perPage) {
			commit('SET_PER_PAGE', perPage);
		},
		searchTermChange({commit}, searchTerm) {
			commit('SET_SEARCH_TERM', searchTerm);
		},
		filtersChange({ commit, state }, filters) {
			commit('SET_FILTERS', {
				...state.filters,
				...filters
			})
		},
	},
};
