import API from '@/libs/axios'
// import { perPageOptions } from '../../constants'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    loading: false,
    pendingCount: 0,
    completedCount: 0,
    rejectedCount: 0,
    pendingRechargesCount: 0,
    pendingWithdrawalsCount: 0,
    completedRechargesCount: 0,
    completedWithdrawalsCount: 0,
    rejectedRechargesCount: 0,
    rejectedWithdrawalsCount: 0,
    totalRechargesCount: 0,
    totalWithdrawalsCount: 0,
    completedRechargesBalance: 0,
    completedWithdrawalsBalance: 0,
    preApprovedRechargesCount: 0,
    moneyTransfersCount: 0,
    completedMoneyTransfersBalance: 0,
    moneyTransfersCount : 0,
    completedMoneyTransfersBalance: 0,
    totalMoneyTransfersCount: 0,
    totalMoneyTransfersBalance: 0,
    moneyTransfersMaintenanceCount: 0,
    dates: [
      // start of day
      moment().startOf('day').toDate(),
      // end of day
      moment().endOf('day').toDate(),
    ]
  },
  getters: {
    totalTransactions: (state) =>
      state.pendingCount + state.completedCount + state.rejectedCount + state.preApprovedRechargesCount,
    // totalRechargesCount: (state) => state.pendingRechargesCount + state.completedRechargesCount + state.rejectedRechargesCount,
    // totalWithdrawalsCount: (state) => state.pendingWithdrawalsCount + state.completedWithdrawalsCount + state.rejectedWithdrawalsCount,
  },
  mutations: {
    SET_TRANSACTIONS_INFO(state, data) {
      state.pendingCount = data.pendingCount
      state.completedCount = data.completedCount
      state.rejectedCount = data.rejectedCount
      state.pendingRechargesCount = data.pendingRechargesCount
      state.pendingWithdrawalsCount = data.pendingWithdrawalsCount
      state.completedRechargesCount = data.completedRechargesCount
      state.completedWithdrawalsCount = data.completedWithdrawalsCount
      state.rejectedRechargesCount = data.rejectedRechargesCount
      state.rejectedWithdrawalsCount = data.rejectedWithdrawalsCount
      state.totalRechargesCount = data.totalRechargesCount
      state.totalWithdrawalsCount = data.totalWithdrawalsCount
      state.completedRechargesBalance = data.completedRechargesBalance
      state.completedWithdrawalsBalance = data.completedWithdrawalsBalance
      state.preApprovedRechargesCount = data.preApprovedRechargesCount
      state.moneyTransfersCount = data.moneyTransfersCount
      state.completedMoneyTransfersBalance = data.completedMoneyTransfersBalance
      state.moneyTransfersCount = data.moneyTransfersCount
      state.completedMoneyTransfersBalance = data.completedMoneyTransfersBalance
      state.totalMoneyTransfersCount = data.totalMoneyTransfersCount
      state.totalMoneyTransfersBalance = state.totalMoneyTransfersBalance
      state.moneyTransfersMaintenanceCount = data.moneyTransfersMaintenanceCount
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    async getTransactionsInfo({ commit, state }, { dates, userId, paymentMethod, bank, gestor }) {
      try {
        commit('SET_LOADING', true)
        console.log('dates', dates)
        // dates to string
        dates = dates ? dates.map((date) => moment(date).toDate()) : state.dates
        const { data: response } = await API.get('transactions', {
          params: {
            dates,
            user_id: userId,
            payment_method: paymentMethod,
            bank,
            gestor,
          },
        })
        console.log('response', response)
        commit('SET_TRANSACTIONS_INFO', response)
        commit('SET_LOADING', false)
        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
  },
}
