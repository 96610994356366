import API from '@/libs/axios';

export default {
	namespaced: true,
	state: {
		user: null,
		isLoadingMe: true,
		company: null,
		paymentTerms: [],
		consecutives: {
			fe: '0000000000',
			nc: '0000000000',
			nd: '0000000000',
			te: '0000000000',
			cce: '0000000000',
			cpce: '0000000000',
			rce: '0000000000',
			qt: '0000000000',
			fec: '0000000000',
			fr: '0000000000',
		},
		permissions: [],
	},
	getters: {},
	mutations: {
		SET_USER(state, user) {
			state.user = user;
		},

		SET_LOADING(state, loading) {
			state.isLoadingMe = loading;
		},
		SET_COMPANY(state, company) {
			state.company = company;
		},
		SET_COMPANIES(state, companies) {
			state.companies = companies;
		},
		SET_INIT_DATA(state, initData) {
			state.paymentTerms = initData.payment_terms;
			state.consecutives = initData.consecutives;
		},
	},
	actions: {
		async login({commit}, {email, password}) {
			try {
				const {data} = await API.post('sign-in', {
					email,
					password,
				});
				if (data.success) {
					commit(
						'SET_USER',
						data?.data
							? {
									...data?.data?.user,
									manage_transactions: data.data.user.manage_transactions || [],
							  }
							: {}
					);
					localStorage.removeItem('permissions');
					localStorage.setItem(
						'permissions',
						JSON.stringify(data?.data?.user?.permissions)
					);
					localStorage.setItem('token', data?.data?.access_token);
				}
				return data;
			} catch (error) {
				console.log(error);
			}
		},
		async me({commit}) {
			commit('SET_LOADING', true);
			try {
				const {data} = await API.get('me-admin');
				if (data.success) {
					commit(
						'SET_USER',
						data?.data
							? {
									...data?.data?.user,
									manage_transactions: data.data.user.manage_transactions || [],
							  }
							: {}
					);
					commit('SET_COMPANY', data?.data?.active_company);
					commit('SET_COMPANIES', data?.data?.companies);

					localStorage.removeItem('permissions');
					localStorage.setItem(
						'permissions',
						JSON.stringify(data?.data?.user?.permissions)
					);
				} else {
					localStorage.removeItem('permissions');
					localStorage.removeItem('token');
				}
				commit('SET_LOADING', false);
				return data;
			} catch (error) {
				commit('SET_LOADING', false);
				localStorage.removeItem('permissions');
				console.log(error);
			}
		},
		async logout({commit}) {
			try {
				await API.post('logout');
				commit('SET_USER', null);
				commit('SET_COMPANY', null);
				localStorage.removeItem('permissions');
				localStorage.removeItem('token');
			} catch (error) {
				console.log(error);
			}
		},
		async getInitData({commit}) {
			try {
				const {data} = await API.get('company-init-data');
				commit('SET_INIT_DATA', data?.data);
				return data?.data;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
