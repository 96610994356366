import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

function findPermission(permissions, namePerm) {
	let verificarMap = undefined;

	if (permissions.length > 0) {
		verificarMap = permissions.find(itemFirstSearch => {
			let verificarFirst =
				itemFirstSearch.key == namePerm && itemFirstSearch.active;

			if (verificarFirst) {
				return true;
			}

			if (itemFirstSearch.submodules.length > 0) {
				let verificarSecond = itemFirstSearch.submodules.find(
					itemSecondSearch =>
						itemSecondSearch.key == namePerm && itemSecondSearch.active
				);

				if (verificarSecond != undefined && itemFirstSearch.active) {
					return true;
				}
			}

			return false;
		});
	}
	return verificarMap;
}

export function verifyPermission({namePerm}) {
	let permissions = JSON.parse(localStorage.getItem('permissions'));

	if (permissions == null) {
		return false;
	}

	let dataVerificada = false;
	let verificarMap = findPermission(permissions, namePerm);

	if (verificarMap != undefined) {
		dataVerificada = true;
	}

	return dataVerificada;
}

function verifyPermissionRoute({namePerm, next}) {
	let permissions = JSON.parse(localStorage.getItem('permissions'));

	if (permissions == null && namePerm != 'login') {
		next({name: 'login'});
		return null;
	}

	if (permissions == null && namePerm == 'login') {
		next();
		return null;
	}

	if (permissions != null && namePerm == 'login') {
		next({name: 'home'});
		return null;
	}

	let dataVerificada = false;
	let verificarMap = findPermission(permissions, namePerm);

	if (verificarMap != undefined) {
		dataVerificada = true;
	}

	if (dataVerificada) {
		next();
		return;
	} else {
		next({name: '404'});
		return;
	}
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return {x: 0, y: 0};
	},
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('@/views/Home.vue'),
			meta: {
				pageTitle: 'Home',
				breadcrumb: [
					{
						text: 'Home',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'dashboard', next: next});
			},
		},
		{
			path: '/roles',
			name: 'roles',
			component: () => import('@/views/Roles/index.vue'),
			meta: {
				pageTitle: 'Roles',
				breadcrumb: [
					{
						text: 'Roles',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'roles', next: next});
			},
		},
		{
			path: '/users-app',
			name: 'users-app',
			component: () => import('@/views/Clients/index.vue'),
			meta: {
				pageTitle: 'Clientes',
				breadcrumb: [
					{
						text: 'Clientes',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'users-app', next: next});
			},
		},
		{
			path: '/users-admin',
			name: 'users-admin',
			component: () => import('@/views/Admins/index.vue'),
			meta: {
				pageTitle: 'Clientes',
				breadcrumb: [
					{
						text: 'Clientes',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'users-admin', next: next});
			},
		},
		// {
		// 	path: '/transactions',
		// 	name: 'transactions',
		// 	component: () => import('@/views/SendMoney/index.vue'),
		// 	meta: {
		// 		pageTitle: 'Envios de dinero',
		// 		breadcrumb: [
		// 			{
		// 				text: 'Envios de dinero',
		// 				active: true,
		// 			},
		// 		],
		// 	},
		// 	beforeEnter(to, from, next) {
		// 		verifyPermissionRoute({namePerm: 'transactions', next: next});
		// 	},
		// },
		{
			path: '/send-money/:id',
			name: 'send-money.detail',
			component: () => import('@/views/SendMoneyDetail/index.vue'),
			meta: {
				pageTitle: 'Detalle de envio de dinero',
				breadcrumb: [
					{
						text: 'Detalle de envio de dinero',
						active: true,
					},
				],
			},
			// beforeEnter(to, from, next) {
			// 	verifyPermissionRoute({namePerm: 'send-money.detail', next: next});
			// },
		},
		{
			path: '/client-detail/:id',
			name: 'client-detail',
			component: () => import('@/views/ClientDetail/index.vue'),
			meta: {
				pageTitle: 'Articulos',
				breadcrumb: [
					{
						text: 'Articulos',
						active: true,
					},
				],
			},
			// beforeEnter(to, from, next) {
			// 	verifyPermissionRoute({namePerm: 'client-detail', next: next});
			// },
		},
		{
			path: '/recharges',
			name: 'recharges',
			component: () => import('@/views/Recharges/index.vue'),
			meta: {
				pageTitle: 'Facturas',
				breadcrumb: [
					{
						text: 'Facturas',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'recharges', next: next});
			},
		},
		{
			path: '/transactions',
			name: 'transactions',
			component: () => import('@/views/Transactions/index.vue'),
			meta: {
				pageTitle: 'Facturas',
				breadcrumb: [
					{
						text: 'Facturas',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'recharges', next: next});
			},
		},
		{
			path: '/recharges/:id',
			name: 'recharges.detail',
			component: () => import('@/views/RechargeDetail/index.vue'),
			meta: {
				pageTitle: 'Detalle recarga',
				breadcrumb: [
					{
						text: 'Detalle recarga',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'recharges.detail', next: next});
			},
		},
		{
			path: '/withdrawals',
			name: 'withdrawals',
			component: () => import('@/views/Withdrawals/index.vue'),
			meta: {
				pageTitle: 'Facturas',
				breadcrumb: [
					{
						text: 'Facturas',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'withdrawals', next: next});
			},
		},
		{
			path: '/withdrawals/:id',
			name: 'withdrawals.detail',
			component: () => import('@/views/WithdrawalDetail/index.vue'),
			meta: {
				pageTitle: 'Detalle retiro',
				breadcrumb: [
					{
						text: 'Detalle retiro',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'withdrawals.detail', next: next});
			},
		},
		{
			path: '/document/:type/:action/:id?',
			name: 'document',
			component: () => import('@/views/NewDocument.vue'),
			meta: {
				pageTitle: 'Nuevo Documento',
				breadcrumb: [
					{
						text: 'Nuevo Documento',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'document', next: next});
			},
		},

		{
			path: '/help-faq',
			name: 'help-faq',
			component: () => import('@/views/Help/index.vue'),
			meta: {
				pageTitle: 'Preguntas frecuentes',
				breadcrumb: [
					{
						text: 'Preguntas frecuentes',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'help-faq', next: next});
			},
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/Login.vue'),
			meta: {
				layout: 'full',
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'login', next: next});
			},
		},
    {
			path: '/recharge-methods',
			name: 'recharge-methods',
			component: () => import('@/views/RechargeMethods/index.vue'),
			meta: {
				pageTitle: 'Métodos de pago',
				breadcrumb: [
					{
						text: 'Métodos de pago',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'payment-methods', next: next});
			},
		},
    {
			path: '/recharge-methods/:id',
			name: 'recharge-methods.detail',
			component: () => import('@/views/RechargeMethodDetail/index.vue'),
			meta: {
				pageTitle: 'Detalle metodo de recarga',
				breadcrumb: [
					{
						text: 'Detalle metodo de recarga',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'payment-methods', next: next});
			},
		},
		{
			path: '/payment-options/:id',
			name: 'payment-options.detail',
			component: () => import('@/views/PaymentOptionDetail/index.vue'),
			meta: {
				pageTitle: 'Detalle opción de pago',
				breadcrumb: [
					{
						text: 'Detalle opción de pago',
						active: true,
					},
				],
			},
			beforeEnter(to, from, next) {
				verifyPermissionRoute({namePerm: 'payment-methods', next: next});
			},
		},
		{
			path: '/error-404',
			name: 'error-404',
			component: () => import('@/views/error/Error404.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '*',
			redirect: 'error-404',
		},
	],
});

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg');
	if (appLoading) {
		appLoading.style.display = 'none';
	}
});

// redirect to login if token no exists in local storage
router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('token');
	if (to.name !== 'login' && !token) {
		next({name: 'login'});
	} else {
		next();
	}

	if (to.name === 'login' && token) {
		next({name: 'home'});
	}
});

export default router;
