import API from '@/libs/axios'
import { perPageOptions } from '../../constants'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    loading: false,
    pendingCount: 0,
    completedCount: 0,
    rejectedCount: 0,
    pendingRechargesCount: 0,
    pendingWithdrawalsCount: 0,
    completedRechargesCount: 0,
    completedWithdrawalsCount: 0,
    rejectedRechargesCount: 0,
    rejectedWithdrawalsCount: 0,
    totalRechargesCount: 0,
    totalWithdrawalsCount: 0,
    completedRechargesBalance: 0,
    completedWithdrawalsBalance: 0,
    preApprovedRechargesCount: 0,
    dates: [
      // start of day
      moment().startOf('day').toDate(),
      // end of day
      moment().endOf('day').toDate(),
    ],
    // super admin
    totalUsers: 0,
    totalRecharges: 0,
    totalWithdrawals: 0,
    totalMoneyTransfers: 0,
    totalTransactions: 0,
    usersBalance: 0,
    usersVipBalance: 0,
    totalBalance: 0,
    // payment methods dashboard
    paymentMethodsDashboardInfo: {
      totalRecharges: 0,
      totalWithdrawals: 0,
      paymentMethods: [],
    },
    loadingPaymentMethodsDashboard: false,
    loadingTransactionsDashboard: false,
    // transactions dashboard
    transactionsDashboardInfo: {
      amountRecharges: 0,
      amountWithdrawals: 0,
      amountTransfers: 0,
      transactions: [],
    },
  },
  getters: {
    totalTransactions: (state) =>
      state.pendingCount + state.completedCount + state.rejectedCount + state.preApprovedRechargesCount,
    // totalRechargesCount: (state) => state.pendingRechargesCount + state.completedRechargesCount + state.rejectedRechargesCount,
    // totalWithdrawalsCount: (state) => state.pendingWithdrawalsCount + state.completedWithdrawalsCount + state.rejectedWithdrawalsCount,
  },
  mutations: {
    SET_DASHBOARD_INFO(state, data) {
      state.pendingCount = data.pendingCount
      state.completedCount = data.completedCount
      state.rejectedCount = data.rejectedCount
      state.pendingRechargesCount = data.pendingRechargesCount
      state.pendingWithdrawalsCount = data.pendingWithdrawalsCount
      state.completedRechargesCount = data.completedRechargesCount
      state.completedWithdrawalsCount = data.completedWithdrawalsCount
      state.rejectedRechargesCount = data.rejectedRechargesCount
      state.rejectedWithdrawalsCount = data.rejectedWithdrawalsCount
      state.totalRechargesCount = data.totalRechargesCount
      state.totalWithdrawalsCount = data.totalWithdrawalsCount
      state.completedRechargesBalance = data.completedRechargesBalance
      state.completedWithdrawalsBalance = data.completedWithdrawalsBalance
      state.preApprovedRechargesCount = data.preApprovedRechargesCount

      // super admin
      state.totalUsers = data.totalUsers
      state.totalRecharges = data.totalRecharges
      state.totalWithdrawals = data.totalWithdrawals
      state.totalMoneyTransfers = data.totalMoneyTransfers
      state.totalTransactions = data.totalTransactions
      state.usersBalance = data.usersBalance
      state.usersVipBalance = data.usersVipBalance
      state.totalBalance = data.totalBalance

    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_PAYMENT_METHODS_DASHBOARD_INFO(state, data) {
      state.paymentMethodsDashboardInfo = data
    },
    SET_LOADING_PAYMENT_METHODS_DASHBOARD(state, loading) {
      state.loadingPaymentMethodsDashboard = loading
    },
    SET_TRANSACTIONS_DASHBOARD(state, data) {
      state.transactionsDashboardInfo = data
    },
    SET_LOADING_TRANSACTIONS_DASHBOARD(state, loading) {
      state.loadingTransactionsDashboard = loading
    }
  },
  actions: {
    async getDashboardInfo({ commit, state }, { dates }) {
      try {
        commit('SET_LOADING', true)
        console.log('dates', dates)
        // dates to string
        dates = dates ? dates.map((date) => moment(date).toDate()) : state.dates
        const { data: response } = await API.get('dashboard', {
          params: {
            dates,
          },
        })
        console.log('response', response)
        commit('SET_DASHBOARD_INFO', response)
        commit('SET_LOADING', false)
        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
    async getPaymentMethodCountsDashboard({ commit, state }, { dates, type }) {
      try {
        commit('SET_LOADING_PAYMENT_METHODS_DASHBOARD', true)
        console.log('dates', dates)
        // dates to string
        dates = dates ? dates.map((date) => moment(date).toDate()) : state.dates
        const { data: response } = await API.get('payment-method-counts-dashboard', {
          params: {
            dates,
            type
          },
        })
        console.log('response', response)
        commit('SET_PAYMENT_METHODS_DASHBOARD_INFO', response.data)
        commit('SET_LOADING_PAYMENT_METHODS_DASHBOARD', false)
        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
    async getTransactionsDashboard({ commit, state }, { dates, type }) {
      try {
        commit('SET_LOADING_TRANSACTIONS_DASHBOARD', true)
        console.log('dates', dates)
        // dates to string
        dates = dates ? dates.map((date) => moment(date).toDate()) : state.dates
        const { data: response } = await API.get('transactions-dashboard', {
          params: {
            dates,
            type
          },
        })
        console.log('response', response)
        commit('SET_TRANSACTIONS_DASHBOARD', response.data)
        commit('SET_LOADING_TRANSACTIONS_DASHBOARD', false)
        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
  },
}
