import API from '@/libs/axios'
import { Decimal } from 'decimal.js'

export default {
  namespaced: true,
  state: {
    clients: [],
    products: [],
    items: [],
    currency: null,
  },
  getters: {
    subtotal: (state) => {
      return state.items.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      )
    },
    total: (state, getters) => {
      return getters.subtotal - getters.discount + getters.tax
    },
    tax: (state, getters) => {
      const taxes = state.items.map((item) => {
        const flatTaxes = item.taxes.flat()
        const trs = flatTaxes.map((tax) => {
          const decimal = new Decimal(tax.tax_type.rate)
          return decimal.toNumber() / 100
        })

        const total = trs.reduce((acc, tr) => acc + tr, 0)

        const decimalDiscount = new Decimal(item.discount || 0)
        const dis = decimalDiscount.toNumber() / 100
        const totalDiscount = item.price * item.quantity * dis
        const subtotal = item.price * item.quantity - totalDiscount

        return subtotal * total
      })

      const totalTax = taxes.reduce((acc, tr) => acc + tr, 0)

      return totalTax
    },
    discount: (state, getters) => {
      const discounts = state.items.map((i) => {
        const decimal = new Decimal(i.discount || 0)
        const dis = decimal.toNumber() / 100

        return i.price * i.quantity * dis
      })

      const totalDiscount = discounts.reduce((acc, tr) => acc + tr, 0)
      return totalDiscount
    },
  },
  mutations: {
    SET_CLIENTS(state, clients) {
      state.clients = clients
    },
    SET_PRODUCTS(state, products) {
      state.products = products
    },
    ADD_ITEM(state, item) {
      state.items.push(item)
    },
    REMOVE_ITEM(state, index) {
      state.items.splice(index, 1)
    },
    UPDATE_ITEM(state, { index, item }) {
      state.items.splice(index, 1, item)
    },
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_CURRENCY(state, currency) {
      state.currency = currency
    },
  },
  actions: {
    async getClients({ commit, state }, { term }) {
      try {
        const { data: response } = await API.get('get-clients', {
          params: {
            term,
          },
        })

        commit('SET_CLIENTS', response?.data)

        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
    async getProducts({ commit, state }, { term }) {
      try {
        const { data: response } = await API.get('get-products', {
          params: {
            term,
          },
        })

        commit('SET_PRODUCTS', response?.data)

        return response?.data
      } catch (error) {
        console.log(error)
      }
    },

    async addItem({ commit, state }, item) {
      try {
        const items = JSON.parse(JSON.stringify(state.items))
        const index = items.findIndex((i) => i.id === item.id)

        if (index > -1) {
          items[index].quantity += item.quantity
          commit('SET_ITEMS', items)
        } else {
          commit('ADD_ITEM', item)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateItems({ commit, state }, items) {
      try {
        commit('SET_ITEMS', items)
      } catch (error) {
        console.log(error)
      }
    },
    updateCurrency({ commit }, currency) {
      commit('SET_CURRENCY', currency)
    },
    updateItem({ commit, state }, { index, item }) {
      commit('UPDATE_ITEM', { index, item })
    },
    removeItem({ commit, state }, index) {
      commit('REMOVE_ITEM', index)
    },
    async createDocument({ commit, state }, data) {
      try {
        const { data: response } = await API.post('invoices', data)
        return response
      } catch (error) {
        console.log(error)
      }
    },
  },
}
