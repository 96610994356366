import API from '@/libs/axios';

export default {
	namespaced: true,
	state: {
		items: [],
		loading: false,
		roles: [],
	},
	getters: {},
	mutations: {
		SET_DATA(state, data) {
			state.roles = data;
		},
		SET_LOADING(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async save({commit, state}, payload) {
			try {
				let url =
					payload.id != null ? `roles_admin/update` : 'roles_admin/create';

				commit('SET_LOADING', true);
				const {data: response} =
					url == 'roles_admin/update'
						? await API.put(url, payload)
						: await API.post(url, payload);

				commit('SET_LOADING', false);
				return response;
			} catch (error) {
				console.log(error);
			}
		},

		async delete({commit, state}, payload) {
			try {
				commit('SET_LOADING', true);
				const {data: response} = await API.post(
					'roles_admin/delete',
					payload
				);
				commit('SET_LOADING', false);
				return response
			} catch (error) {
				console.log(error);
			}
		},
		

		async getRolesAndPermissions({commit, state}, payload) {
			try {
				commit('SET_LOADING', true);
				const {data: response} = await API.get(
					'roles_admin/roles-and-permissions',
					{
						params: {},
					}
				);
				commit('SET_DATA', response.data);

				commit('SET_LOADING', false);
				return response?.data;
			} catch (error) {
				console.log(error);
			}
		},
		async changeStatusModulePermission({commit, state}, payload) {
			try {
				const {data: response} = await API.post(
					'roles_admin/change-module-permission-status',
					payload
				);
				return response;
			} catch (error) {
				console.log(error);
			}
		},

		async changeStatusSubModulePermission({commit, state}, payload) {
			try {
				const {data: response} = await API.post(
					'roles_admin/change-submodule-permission-status',
					payload
				);
				return response;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
