export const perPageOptions = [15, 20, 30, 40, 50, 100]

export const pairDocumentTypes = {
  invoice: 'fe',
  nc: 'nc',
  nd: 'nd',
  ticket: 'te',
  cce: 'cce',
  cpce: 'cpce',
  rce: 'rce',
  qt: 'qt',
  fce: 'fec',
  fr: 'fr',
}
