import API from '@/libs/axios'
import { perPageOptions } from '../../constants'

export default {
  namespaced: true,
  state: {
    items: [],
    perPage: 15,
    currentPage: 1,
    totalRows: 3,
    pageOptions: perPageOptions,
    searchTerm: '',
    loading: false,
    detail: null,
  },
  getters: {},
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_PER_PAGE(state, perPage) {
      state.perPage = perPage
    },
    SET_CURRENT_PAGE(state, currentPage) {
      state.currentPage = currentPage
    },
    SET_TOTAL_ROWS(state, totalRows) {
      state.totalRows = totalRows
    },
    SET_SEARCH_TERM(state, searchTerm) {
      state.searchTerm = searchTerm
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_DETAIL(state, detail) {
      state.detail = detail
    },
  },
  actions: {
    async getDetail({ commit, state }, payload) {
      try {
        commit('SET_LOADING', true)
        const { data: response } = await API.get(`payment-option-accounts/${payload.id}`)
        commit('SET_LOADING', false)

        commit('SET_DETAIL', response?.data)

        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
    async get({ commit, state }, { currentPage, currencyId, bankId, type, is_crypto }) {
      try {
        commit('SET_LOADING', true)
        commit('SET_ITEMS', [])
        const { data: response } = await API.get(`payment-option-accounts`, {
          params: {
            page: currentPage,
            per_page: state.perPage,
            searchTerm: state.searchTerm,
            currency_id: currencyId,
            bank_id: bankId,
            type,
            is_crypto
          },
        })

        commit('SET_PER_PAGE', response?.data?.per_page)
        commit('SET_CURRENT_PAGE', response?.data?.current_page)
        commit('SET_TOTAL_ROWS', response?.data?.total)
        commit('SET_ITEMS', response?.data?.data)
        commit('SET_LOADING', false)
        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
    async save({ commit, state }, payload) {
      try {
        commit('SET_LOADING', true)
        const { data: response } = await API.post('payment-option-accounts', payload)

        if (!payload?.id) {
          commit('SET_ITEMS', [...state.items, response?.data])
          commit('SET_LOADING', false)
        } else {
          commit(
            'SET_ITEMS',
            state.items.map((item) =>
              item.id === payload.id ? response?.data : item
            )
          )
          commit('SET_LOADING', false)
        }
        return response
      } catch (error) {
        console.log(error)
      }
    },
    async delete({ commit, state }, payload) {
      try {
        commit('SET_LOADING', true)
        const { data: response } = await API.delete(`payment-option-accounts/${payload.id}`)
        if (response?.success) {
          const items = state.items.filter((item) => item.id !== payload.id)
          commit('SET_ITEMS', items)
        }
        commit('SET_LOADING', false)
        return response
      } catch (error) {
        console.log(error)
      }
    },
    async changeStatus({ commit, state }, payload) {
      try {
        const { data: response } = await API.patch(
          `payment-option-accounts/${payload.id}/active`,
          payload
        )
        if (response?.success) {
          // change available status
          let items = state.items.map((item) =>
            item.id === response.data.id  ? response?.data : item.bank_account_type_id === response.data.bank_account_type_id ? { ...item, available: false } : item
          )
          commit('SET_ITEMS', items)

          if (response.data.bank_account_type.isASet) {
            items = items.map((item) =>
              item.currency_id === response.data.currency_id &&
              item.id !== response.data.id &&
              item.bank_account_type.isASet ? { ...item, available: false } : item
            )
            commit('SET_ITEMS', items)
          }

          return response
        }
      } catch (error) {
        console.log(error)
      }
    },
    async saveExchangeRate({ commit, state }, payload) {
      try {
        const { data: response } = await API.post('exchange-rate', payload)
        return response
      } catch (error) {
        console.log(error)
      }
    },
    perPageChange({ commit }, perPage) {
      commit('SET_PER_PAGE', perPage)
    },
    searchTermChange({ commit }, searchTerm) {
      commit('SET_SEARCH_TERM', searchTerm)
    },
  },
}
