import API from '@/libs/axios'
import { perPageOptions } from '../../constants'

export default {
  namespaced: true,
  state: {
    items: [],
    perPage: 15,
    currentPage: 1,
    totalRows: 3,
    pageOptions: perPageOptions,
    searchTerm: '',
    filters: {},
    loading: false,
  },
  getters: {},
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_PER_PAGE(state, perPage) {
      state.perPage = perPage
    },
    SET_CURRENT_PAGE(state, currentPage) {
      state.currentPage = currentPage
    },
    SET_TOTAL_ROWS(state, totalRows) {
      state.totalRows = totalRows
    },
    SET_SEARCH_TERM(state, searchTerm) {
      state.searchTerm = searchTerm
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
  },
  actions: {
    async get({ commit, state }, { currentPage }) {
      try {
        commit('SET_LOADING', true)
        const { data: response } = await API.get('users', {
          params: {
            page: currentPage || state.currentPage,
            per_page: state.perPage,
            searchTerm: state.searchTerm,
            filters: state.filters
          },
        })

        commit('SET_PER_PAGE', response?.data?.per_page)
        commit('SET_CURRENT_PAGE', response?.data?.current_page)
        commit('SET_TOTAL_ROWS', response?.data?.total)
        commit('SET_ITEMS', response?.data?.data)
        commit('SET_LOADING', false)
        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
    async save({ commit, state }, payload) {
      try {
        commit('SET_LOADING', true)
        const { data: response } = await API.post('users', payload)

        if (!payload?.id) {
          commit('SET_ITEMS', [...state.items, response?.data])
          commit('SET_LOADING', false)
        } else {
          commit(
            'SET_ITEMS',
            state.items.map((item) =>
              item.id === payload.id ? response?.data : item
            )
          )
          commit('SET_LOADING', false)
        }
        return response?.data
      } catch (error) {
        console.log(error)
      }
    },
    async resetPin({ commit, state }, payload) {
      try {
        const { data: response } = await API.patch(
          `users/${payload.id}/reset-pin`,
          payload
        )
        return response
      } catch (error) {
        console.log(error)
      }
    },
    async deleteUser({ commit, state }, payload) {
      try {
        const { data: response } = await API.delete(`users/${payload.id}`)
        return response
      } catch (error) {
        console.log(error)
      }
    },
    async changeActiveStatus({ commit, state }, payload) {
      try {
        const { data: response } = await API.patch(
          `users/${payload.id}/active`,
          payload
        )
        if (response?.success) {
          // change property active status
          const items = state.items.map((item) =>
            item.id === payload.id ? { ...item, active: payload.active } : item
          )

          commit('SET_ITEMS', items)

          return response

          // return new Promise((resolve, reject) => {
          //   resolve(response)
          // })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateUserApp({commit, state}, payload) {
			try {
				commit('SET_LOADING', true);

				let url = payload.id != null ? `users/${payload.id}` : 'users';

				const {data: response} =
					url != 'users'
						? await API.put(url, payload)
						: await API.post(url, payload);

				if (!payload?.id) {
					commit('SET_ITEMS', [...state.items, response?.data]);
					commit('SET_LOADING', false);
				} else {
					commit(
						'SET_ITEMS',
						state.items.map(item =>
							item.id === payload.id ? response?.data : item
						)
					);
					commit('SET_LOADING', false);
				}
				return response;
			} catch (error) {
				console.log(error);
			}
		},
    async changeVipStatus({ commit, state }, payload) {
      try {
        const { data: response } = await API.patch(
          `users/${payload.id}/vip`,
          payload
        )
        if (response?.success) {
          // change property vip status
          const items = state.items.map((item) =>
            item.id === payload.id ? { ...item, vip: payload.vip } : item
          )

          commit('SET_ITEMS', items)

          return response

          // return new Promise((resolve, reject) => {
          //   resolve(response)
          // })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async uploadDocuments({ commit, state }, payload) {
      try {
        console.log('formDat', payload.id)

        const formData = new FormData()
        formData.append('id', payload.id)
        formData.append('verification_type', payload.verificationType)
        formData.append('front', payload.front)
        formData.append('back', payload.back)
        formData.append('selfie', payload.selfie)

        console.log('formData', formData)

        const { data: response } = await API.post(
          `users/upload-documents`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        return response
      } catch (error) {
        console.log(error)
      }
    },
    perPageChange({ commit }, perPage) {
      commit('SET_PER_PAGE', perPage)
    },
    searchTermChange({ commit }, searchTerm) {
      commit('SET_SEARCH_TERM', searchTerm)
    },
    filtersChange({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },
  },
}
