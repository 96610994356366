import API from '@/libs/axios';
import {perPageOptions} from '../../constants';

export default {
	namespaced: true,
	state: {
		items: [],
		items_select: [],
		perPage: 15,
		currentPage: 1,
		totalRows: 3,
		pageOptions: perPageOptions,
		searchTerm: '',
		loading: false,
	},
	getters: {},
	mutations: {
		SET_ITEMS(state, items) {
			state.items = items;
		},
		SET_ITEMS_SELECT(state, items) {
			state.items_select = items;
		},
		SET_PER_PAGE(state, perPage) {
			state.perPage = perPage;
		},
		SET_CURRENT_PAGE(state, currentPage) {
			state.currentPage = currentPage;
		},
		SET_TOTAL_ROWS(state, totalRows) {
			state.totalRows = totalRows;
		},
		SET_SEARCH_TERM(state, searchTerm) {
			state.searchTerm = searchTerm;
		},
		SET_LOADING(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async changeActiveStatus({commit, state}, payload) {
			try {
				const {data: response} = await API.patch(
					`help/${payload.id}/active`,
					payload
				);
				if (response?.success) {
					// change property active status
					const items = state.items.map(item =>
						item.id === payload.id ? {...item, active: payload.active} : item
					);

					commit('SET_ITEMS', items);

					return response;
				}
			} catch (error) {
				console.log(error);
			}
		},
		async get({commit, state}, {currentPage, filters}) {
			try {
				commit('SET_LOADING', true);
				const {data: response} = await API.get('help', {
					params: {
						page: currentPage,
						per_page: state.perPage,
						searchTerm: state.searchTerm,
						filters,
					},
				});

				commit('SET_PER_PAGE', response?.data?.per_page);
				commit('SET_CURRENT_PAGE', response?.data?.current_page);
				commit('SET_TOTAL_ROWS', response?.data?.total);
				commit('SET_ITEMS', response?.data?.data);
				commit('SET_LOADING', false);
				return response?.data;
			} catch (error) {
				console.log(error);
			}
		},
		async save({commit, state}, payload) {
			try {
				commit('SET_LOADING', true);

				let url = payload.id != null ? `help/${payload.id}` : 'help';

				const {data: response} =
					url != 'help'
						? await API.put(url, payload)
						: await API.post(url, payload);

				if (!payload?.id) {
					commit('SET_ITEMS', [...state.items, response?.data]);
					commit('SET_LOADING', false);
				} else {
					commit(
						'SET_ITEMS',
						state.items.map(item =>
							item.id === payload.id ? response?.data : item
						)
					);
					commit('SET_LOADING', false);
				}
				return response;
			} catch (error) {
				console.log(error);
			}
		},

		async getSelect({commit, state}, payload) {
			try {
				const {data: response} = await API.get('help/select', payload);
				if (response?.success) {
					if (response.data.length > 0) {
						let dataSelect = response.data.map(item => {
							return {value: item.id, label: item.title};
						});
						commit('SET_ITEMS_SELECT', dataSelect);
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		async delete({commit, state}, payload) {
			try {
				commit('SET_LOADING', true);
				const {data: response} = await API.post('help/delete-help', payload);
				if (response?.success) {
					const items = state.items.filter(item => item.id !== payload.id);
					commit('SET_ITEMS', items);
				}
				commit('SET_LOADING', false);
			} catch (error) {
				console.log(error);
			}
		},
		perPageChange({commit}, perPage) {
			commit('SET_PER_PAGE', perPage);
		},
		searchTermChange({commit}, searchTerm) {
			commit('SET_SEARCH_TERM', searchTerm);
		},
	},
};
