export function interceptors(API) {
  // Add a request interceptor
  API.interceptors.request.use(
    function (config) {
      const AUTH_TOKEN = localStorage.getItem('token')
      if (AUTH_TOKEN) {
        config.headers['Authorization'] = `Bearer ${AUTH_TOKEN}`
      }

      // Do something before request is sent
      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  API.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      // if status code is 401, then clear the token and redirect to login
      if (error.response.status === 401) {
        localStorage.removeItem('token')
        if (error.response.config.url !== 'me-admin') {
          window.location.href = '/login'
        }
      }
      return Promise.reject(error)
    }
  )
}
